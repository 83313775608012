/* 英语 */
export default {
  emailRegister: 'Email Register',
  registerNotice:
    'Unregistered accounts are automatically registered and logged in',
  enterEmail: 'Please enter your email address',
  enterCode: 'Please enter your code',
  getCode: 'Get code',
  signIn: 'Sign in now',
  signPropt: 'By logging in, you agree to the',
  policy: 'Privacy Policy',
  agreement: 'User Agreement',
  emailRuleError: 'The email format is incorrect',
  starcard: `Star Card member benefits`,
  more: `More`,
  regisgerAndEnjoy: `Register to Enjoy Great Shows`,

  describe: `M-Card is the proof of rights for the M-Plan promotion system. M-Card is divided into M-Card and M-Card Pro. Becoming M-Card user can earn rewards.`,
  starReward: `M-Card Rewards`,
  unlock: ` Unlock`,
  text1: `· Invitation Reward: Direct invitees to become M-Card can earn 10% rewards.`,
  text2: `· Copyright investment promotion reward: Direct invitees to invest in copyright, you can earn 10% of the daily income of invitee.`,
  text3: `· Movie rewards: Direct invitees to become Movie VIP can earn 20% reward; Direct invitees to buy Drama Point can earn 40% reward.`,
  starClu: `M-Team Rewards`,
  text4: `Consult customer service to learn more benefits.`,
  copyright: `Copyright`,
  td1: `Annualized Return`,
  td2: `Initial Investment Amount`,
  td3: `Completed/Total`,
  warning: `*Daily revenue will fluctuate according to the effect of promotiom.`,
  schedule: `Crowdfunding Progress`,
  buynow: `Buy Now`,
  download: `Download`,
  go: `Go`,
  android: `Android`,
  apple: `App Store`,
  Characteristic: `Characteristic`,
  Participation: `Participation Amount`,
  method: `Payment Method`,
  Payamount: `Payment Amount`,
  jump: `Pay`,
  name1: `Skits`,
  name2: `Multiple Types`,
  name3: `M-card Member`,
  name4: `Promotion Income`,
  describe1: `Quality Assurance`,
  describe2: `Think What You Think`,
  describe3: `Huge Subsidy`,
  describe4: `Good Sideline`,
  warning1: `The content cannot be empty`,
  success1: `Binding Successful`,
  ends: `Countdown Ends`,
  warning2: `You have not login yet.`,

  experienceStar: `M-Card`,
  ExclusiveStar: `M-Card Pro`,
  noCard: `No M-Card`,
  aboutus: `About Us`,
  understand: `About Us`,
  Snapshort: `${window.name} team:`,
  Innovators: `Innovators reshaping the`,
  industry: `film and television industry`,
  paragraph1_1: `In the ever-changing digital era, a group of developers passionate about film and television art are working to reshape the audience's viewing experience.`,
  paragraph1_2: `Our team, ${window.name}, takes innovation as its core and technology as its driver, and is leading the new trend in the film and television industry.`,
  paragraph1_3: `${window.name} is a mobile application focusing on short drama content. We carefully select high-quality short dramas to adapt to the fast-paced lifestyle of modern audiences.`,
  paragraph1_4: `Our app allows users to watch the latest and most popular short dramas anytime, anywhere, while providing rich personalized recommendations so that every user can find their favorite content.`,
  paragraph2_1: `Our team consists of a group of experienced and technically proficient developers who are proficient in film and television content production, big data analysis, artificial intelligence recommendation and other technologies.`,
  paragraph2_2: `This allows us to efficiently produce and update content while providing customized recommendations for each user.`,
  paragraph2_3: `Our vision is to allow every user to find their own fun and inspiration in the drama Gravity. We believe that through our efforts, ${window.name} will become a part of your life, allowing you to find a moment of peace and joy in your busy life.`,
  paragraph3_1: `Whether you're looking for light-hearted entertainment or a story that's thought-provoking, ${window.name} has you covered.`,
  paragraph3_2: `Let's explore the infinite possibilities of film and television art together.`,
  period: `M-Card`,
  Premier: `M-Card Pro`,
  Expiration: `End Date:`,
  renew: `Renew Immediately`,
  balance: `M-Card Balance`,
  Income: `Cumulative Income`,
  wallet: `Transfer to Wallet`,
  daily: `Daily Task`,
  nocompleted: `Not completed today`,
  code: `Movie VIP`,
  Residue: `Remaining redemption code`,
  reward: `Today's Income`,
  type: `Type`,
  amount: `Amount`,
  time: `Time`,
  none: `No more content`,
  load: `Loading`,
  loadmore: `Load more`,
  success2: `Transferred to wallet successful`,
  five: `M5 Talent`,
  four: `M4 Talent`,
  Samsung: `M3 Talent`,
  two: `M2 Talent`,
  one: `M1 Talent`,
  recommendation: `Invitation Rewards`,
  rewards: `Rewards`,
  taskRewards: `Task Rewards`,
  starRewards: `Copyright Promotion Rewards`,
  MovieRewards: `M-Card Promotion Rewards`,
  annualized: `Annualized Return`,
  Initial: `Initial Investment Amount`,
  CompletedTotal: `Completed/Total`,
  tishi: `*Daily revenue will fluctuate according to the effect of promotiom.`,
  Crowdfunding: `Crowdfunding Progress:`,
  dividend: `Crowdfunding Dividend Balance`,
  Project: `Project Name`,
  Cumulative: `Cumulative Income`,
  Dividend: `Daily Rare of Return`,
  investment: `Investment Amount`,
  name5: `COPYRIGHT`,
  name6: `OWNED`,
  title1: `Ongoing`,
  title2: `Ended`,
  title3: `Participation History`,
  title4: `Revenue Details`,
  text5: `Participation time`,
  text6: `Revenue time`,
  text7: `Cumulative Income`,
  text8: `Disbursement Amount`,

  remaining: `Remaining Tasks:`,
  Claim: `Get Task Deadline:`,
  Submission: `Submission deadline:`,
  Completion: `Completion time:`,
  Completed: `Completed`,
  Complete: `Complete`,
  error: `Task review failed, please resubmit the picture for review.`,
  warning3: `The task completion image should display the date of the day.`,
  sample: `Sample`,
  Copywriting: `Promotion Slogan`,
  Claim2: `Get Task`,
  SubmitTask: `Submit Task`,
  Waiting: `Reviewing`,
  Resubmit: `Resubmit`,
  resubmit: `,Please resubmit`,
  Expand: `Expand`,
  Recover: `Return`,
  Deadline: `Deadline:`,
  text9: `1.The task completion image should display promotion slogan.`,
  text10: `2.The text content in the task completion image should display the date of the day.`,
  success3: `Download Successful`,
  error2: `Download Failed`,
  success4: `Copy Successful`,
  warning4: `Please upload the task completion image.`,
  success5: `Upload Successful`,
  emaillogin: `Login With Email`,
  warning5: `Unregistered user will automatically registered and logged in`,
  text11: `Get Code`,
  Sign: `Login Now`,
  text12: `By login, you agree to the`,
  Privacy: `Privacy Policy`,
  Agreement: `User Agreement`,
  warning6: `Please enter your email address`,
  warning7: `Please enter the correct email address`,
  warning8: `Verification code can not be empty`,
  warning9: `Please enter your email address`,
  retrieve: `Retrieve`,
  Payment: `Confirm Payment`,
  InformationFilling: `Fill in the information`,
  BalanceCombinationPayment: `Mixed Payment`,
  AvailableBalance: `Available Balance:`,
  will: `Send to`,
  setCode: ` the verification code`,
  BalanceDeduction: `After balance deduction, still need to pay`,
  StarClusterLevel: `M-Team Level`,
  PersonalPerformance: `Personal Performance`,
  StarClusterPerformance: `M-Team Performance`,
  memberNumber: `Number of M-Team`,
  NumberStar: `Number of M-Card`,
  NumberDirectly: `Direct Invitees`,
  activeMembers: `Number of active user`,

  title_1: `Login`,
  title_2: `Home`,
  title_3: `Wallet`,
  title_4: `M-Card`,
  title_5: `Copyright`,
  title_6: `Community`,
  title_7: `About Us`,
  title_8: `Community Benefits`,
  title_9: `Switch Account`,
  title_10: `Log Out`,
  title_11: `Redemption Code`,
  title_13: `IM chat`,
  title_12: `Transaction Market`,

  interests: `M-Card Benefits`,
  log_fir: `Please login.`,
  name7: `Free Content`,
  name8: `Daily Task`,
  name9: `M-Card Member`,
  name10: `More Benefits`,
  describe5: `Get Free Movie VIP`,
  describe6: `Huge Subsidy`,
  describe7: `Share&Invite`,
  describe8: `Unlock for Other Benefits`,

  Invite: `Share&Invite`,
  invitation: `My Invitation Code`,
  directly: `Direct Invitees`,
  indirect: `Indirect Invitees`,
  Copy_link: `Copy Invitation Link`,
  Fill: `Fill in the invitation code`,
  inv_code: `Invited Code: `,
  Replicating: `Copy Successful`,
  error3: `Copy Failed`,
  error4: `Paste Failed:`,

  Apple_login: `Login With Apple`,
  Google_login: `Login With Google`,
  Email_login: `Login With Email`,

  Commission: `Commission Rate:`,
  nolevel: `No M-Team Level`,
  community: `Number of M-Team`,
  Community1: `Directly Promote Performance`,
  promoted: `Number of Invitees Today`,
  Promote: `Copyright Promotion Reward`,
  balance2: `Balance`,
  Withdraw: `Withdrawal`,
  Transfer: `Transfer`,
  Interpersonal: `M-Team Promotion`,
  mixpayTips: `Prioritize wallet balance to pay`,
  noBalanceL: `Wallet balance is insufficient`,

  days: `Days`,
  Selected: `Selected`,
  Select: `Select`,
  paid: `Amount`,
  gopay: `Pay`,
  warning10: `Choose Payment Method`,

  progress: `Payment in progress`,
  cancel: `Cancel`,
  confirm: `Confirm`,
  Paid: `Paid`,
  warning11: `Paid Successful`,

  Remaining: `Remaining redemption code:`,
  Exchange: `Redeem List`,
  Redemption: `Redemption Code`,
  Duration: `Duration`,
  Period2: 'Redemption Deadline',
  State: `State`,
  Function: `Function`,
  nouse: `Not used`,
  Used: `Used`,
  Copy: `Copy`,

  Wallet2: `Wallet Balance History`,
  Variable: `Amount(${window.currency})`,
  OrderID: `ID`,
  Balance3: `Withdraw`,
  Balance5: `Transfer`,
  information: `Fill in the information`,
  Amount4: `Withdrawal Amount`,
  Balance6: `Transfer Amount`,
  Balance4: `Balance:`,
  nomoney: `Wallet balance is insufficient`,
  crowdfunding: `Participate copyright`,

  title_name: `User Agreement`,
  t1: `This agreement shall be issued and come into effect on March 15, 2023, with the latest revision made on March 15, 2023.`,
  t2: `${window.region} ${window.company} Technology Co., Ltd. is the registered copyright owner of this software and enjoys the copyright of this software in accordance with the law. This user agreement (hereinafter referred to as 'this agreement') is a valid contract between ${window.company} Technology Co., Ltd. (hereinafter referred to as "${window.company} Technology", "us") and the user (also referred to as "you") regarding the installation and use of the client software product "Juyouli" (hereinafter referred to as "this software"). By clicking to confirm this agreement or choosing to accept it in any other way, you indicate that you have reached an agreement with ${window.company} Technology and agree to accept all the following provisions of this agreement. This software is operated by ${window.company} Technology and provides video services to you, enjoying rights, fulfilling obligations, and assuming legal responsibilities in accordance with the law.`,
  t3: `Once this agreement is changed, the software will prompt for modification on the software page. Once the modified usage agreement is displayed on the announcement page, it effectively replaces the original agreement. You should log in to this software at any time to check the latest protocols. Before using the various services provided by this software, you should carefully read this agreement. If you do not agree to the modification of this agreement, you may voluntarily cancel the services provided by this software. Otherwise, your use will be deemed as your acceptance of this agreement and the entire content modified at any time. `,
  t4: `1、 How do we collect and use your personal information`,
  t5: `Personal information refers to various types of information recorded electronically or in other ways that can individually or in combination with other information identify the identity of a specific natural person or reflect the activities of a specific natural person.`,
  t6: `${window.company} Technology and the registered copyright owner of this product will only collect and use your personal information for the purposes, methods, and scope described in this policy when providing services to you:`,
  t7: `1. Application Function 1: Become a Registered User`,
  t8: `When applying to become a registered user of this product, you need to provide the following information: your device information.`,
  t9: `The above information you provided authorizes us to continue using this service for you. When you cancel your account, we will stop using and delete the above information.`,
  t10: `2. Application function 2: Display operation logs within the application`,
  t11: `When you use our services, this product will record your operation logs, including browsing and clicking records, adding TV shows, watching TV shows, and participating in activities. The above information you provided authorizes us to continue using this service for you. When you cancel your account, we will stop using and delete the above information.`,
  t12: `3. Application Function Three: Subscription and Payment`,
  t13: `When you use this product, the records generated when ordering VIP membership and episode payment services, including subscription records and consumption records, will be stored on our server. The above information is necessary for product functionality. If you do not agree to our recording of the above information, we will not be able to provide you with episode purchase services.`,
  t14: `4. Application Function Four: Security Services`,
  t15: `To provide you with secure and stable services, ensure the security of your account and property, and prevent false login, in order to use the functions and services of this product normally, we may automatically record certain information when you use our product or service, including: device or software information: configuration information provided by your mobile device, web browser or other programs used to access our services, your IP address, software version information used by your mobile device, device identification code (IMEI, MAC address), device unique identifier (Android ID, OAID) information, and device serial number. If you do not agree to our recording of the aforementioned information, you may not be able to complete the risk control process and use our product. We may also automatically record whether you have installed Alipay APP. If you do not agree with us to record the above information, your normal use of this product will not be affected.`,
  t16: `5. Application Function 5: Enabling Permissions`,
  t17: `We promise not to exceed the scope or frequency of collecting your personal information, and never to collect or use information that is not necessary or has no reasonable application scenarios for the services of this product.`,
  t18: `When using this product, for the purpose of providing you with services, this product may request the following permissions for terminal devices in certain scenarios where you use the application. Of course, you can refuse to enable the following permissions, or turn off the following permissions for this product in the settings of your phone. Turning off the following permissions will not affect your use of other functions of this product:`,
  t19: `Access phone: By obtaining your phone status permission, we record your device identification code (i.e. IMEI or IMSI code) for anonymization processing, for advertising display and security verification;`,
  t20: `Access local storage: By enabling local storage permissions, download the episodes within this product to your phone's storage;`,
  t21: `Access photos: You can directly select photos or images from your phone to upload or provide proof when communicating with customer service;`,
  t22: `Access media content and files on your device: cache for reading and writing episode covers and event images, improving app usage fluency.`,
  t23: `Enable wireless data: You can achieve the networking functions required by this product by connecting to wireless or cellular networks;`,
  t24: `Access the installation program list: By obtaining your installation program list, we can confirm whether you have installed the products we promote, so that we can promptly distribute corresponding rewards to you.`,
  t25: `Turn on microphone and voice recognition: You can control the voice reading function and voice control function of this product through voice;`,
  t26: `Use clipboard: You can copy and paste customer service phone numbers, or choose not to use clipboard permissions.`,
  t27: `Obtain device network permissions for the purpose of using dictionaries, encyclopedias, and voice reading;`,
  t28: `Get rough location: When browsing certain advertisements, obtain rough location permission;`,
  t29: `Access search location: When browsing certain advertisements, it will obtain search location permission;`,
  t30: `Using a camera: When users provide feedback, they may gain permission to use the camera.`,
  t31: `2、 How do we use cookies and similar technologies`,
  t32: `Cookies and similar technologies are commonly used in the Internet. To ensure the normal operation of this platform and provide you with an easier access experience, we may store small data files called cookies on your mobile device. Cookies typically contain identifiers, site names, and some numbers and characters. With the help of cookies, we will record your login information and operation information in this product, so that we can store your usage habits and help determine the security status of your account, providing you with a more relaxed user experience and services.`,
  t33: `We will not use cookies for any purpose other than those stated in this policy. You can manage or deactivate cookies according to your preferences. For more information, please refer to AboutCookies. org. If cookies are deactivated, you may not be able to enjoy the best service experience, and our recommended content may also be less relevant to you.`,
  t34: `3、 How do we share, transfer, and publicly disclose your personal information`,
  t35: `（1） Sharing`,
  t36: `We will not share your personal information with any company, organization, or individual other than ${window.company} Technology and the registered copyright owner and affiliated companies of this product, except for the following situations:`,
  t37: `1. Sharing with explicit consent: After obtaining your explicit consent, we will share your personal information with other parties.`,
  t38: `2. We may share your personal information with the public in accordance with laws and regulations or mandatory regulations of government regulatory authorities.`,
  t39: `3. Sharing with authorized partners: Some of our services will be provided by authorized partners solely for the purposes stated in this policy. We may share some of your personal information with our partners to provide better customer service and user experience. We will only share your personal information for legitimate, legitimate, necessary, specific, and clear purposes, and only share personal information necessary for providing services. Our partners have no right to use the shared personal information for any other purpose.`,
  t40: `At present, our authorized partners include the following types:`,
  t41: `1) Suppliers, service providers, third-party agents, and other partners. We send information to suppliers, service providers, third-party agents, and other partners worldwide who support our business, including providing technical infrastructure services, analyzing the usage of our services, measuring their effectiveness, providing customer services, facilitating payments, or conducting academic research and surveys. With your explicit prior consent, we may share your consumption records, subscription records, recharge records, order records, deleted viewing records, and consumption activity consumption records with third-party agents after adopting encryption and anonymization methods. If you do not agree to our sharing of the above information, it will not affect your normal use of this product.`,
  t42: `2) Analysis service data service providers: In order to provide you with better services, we cooperate with third-party data service providers including Youmeng+to predict your feature preferences. In order to ensure the security of your data, this prediction is provided in the form of exponential scores, and data analysis is conducted under strict de identification security measures, so as not to disclose your personal information.`,
  t43: `3) Authorized partners for advertising and analysis services. Unless we obtain your permission, we will not share your personal identification information (referring to information that can identify your identity, through which you can be contacted or identified) with partners who provide advertising and analysis services. But we may share your personal information with authorized partners in advertising and analysis services after anonymization, including: network identification information (IP address), software version number, software package name, software installation list, device connection information (telecom operator, network environment), device attribute information (device manufacturer, device model, operating system version, IMEI, OAID, IMSI, sensor, device serial number), to help them improve the effective reach rate of advertisements without recognizing your individual.`,
  t44: `The permissions required for third-party SDK access services. Our products and services may include third-party products and services, as well as links to third-party websites. When you use these products or services, you may also collect your information. After the user has agreed to authorize, personal information will be sent to a third party. When the third party's identity changes, the user's consent will be obtained again through a pop-up window, and we will share personal information with the third party.`,
  t45: `（2） Transfer`,
  t46: `We will not transfer your personal information to any company, organization, or individual, except in the following circumstances:`,
  t47: `1. Transfer with explicit consent: After obtaining your explicit consent, we will transfer your personal information to other parties;`,
  t48: `2. When it comes to mergers, acquisitions, or bankruptcy liquidation, if it involves the transfer of personal information, we will require the new company or organization that holds your personal information to continue to be bound by this privacy policy. Otherwise, we will require the company or organization to seek your authorization and consent again.`,
  t49: `（3） Public disclosure`,
  t50: `We will only publicly disclose your personal information in the following circumstances:`,
  t51: `1. After obtaining your explicit consent;`,
  t52: `2. Legal disclosure: We may publicly disclose your personal information in legal, procedural, litigation, or mandatory requirements from government authorities.`,
  t53: `（4） The types of personal information that may be involved in external sharing include device identification codes and product version information; The types of personal information that may be involved in public disclosure include watching TV series and viewing duration.`,
  t54: `We are aware of the corresponding legal responsibilities for sharing, transferring, and publicly disclosing personal information to the public, and will take reasonable measures to ensure the security of personal information.`,
  t55: `4、 How do we protect and save your personal information`,
  t56: `（1） Technical protection measures`,
  t57: `We attach great importance to the security of your personal information and will strive to take various reasonable security measures that comply with industry standards to protect your information from leakage, damage, or loss, including but not limited to SSL, encrypted storage of privacy information, and access control in data centers. We will use encryption technology to enhance the security of personal information; We will use trusted protection mechanisms to prevent malicious attacks on personal information; We will deploy access control mechanisms to ensure that only authorized personnel can access personal information.`,
  t58: `（2） Security management system`,
  t59: `We have an industry-leading data security management system centered around the data lifecycle, which enhances the security of the entire system from multiple dimensions such as organizational construction, system design, personnel management, product technology, and personal information security impact assessment.`,
  t60: `We have also implemented strict management measures for employees or outsourced personnel who may come into contact with your information, including but not limited to implementing different permission controls based on their positions, signing confidentiality agreements with them, and monitoring their operations.`,
  t61: `（3） Account protection`,
  t62: `Your account has security protection functions, please keep your account and password information safe. If you find that your account has been stolen or your personal information has been leaked, please contact us in a timely manner so that we can take appropriate measures.`,
  t63: `（4） Information security incident handling`,
  t64: `Despite the aforementioned security measures, please also understand that there are no comprehensive security measures on the information network.`,
  t65: `If a personal information security incident unfortunately occurs, we will inform you in accordance with the requirements of laws and regulations: the basic situation and possible impact of the security incident, the disposal measures we have taken or will take, suggestions for you to independently prevent and reduce risks, and remedial measures for you. We will inform you of the relevant situation of the incident by phone or notification. If it is difficult to inform the individual information subject one by one, we will take reasonable and effective measures to publish an announcement. At the same time, we will also report the handling of personal information security incidents in accordance with regulatory requirements.`,
  t66: `（5） Shelf life`,
  t67: `We only store your personal information in your country. We will take all reasonable and feasible measures to ensure that unrelated personal information is not collected. We will only retain your personal information for the shortest period required by law to achieve the objectives stated in this policy, unless an extension of the retention period is required or permitted by law.`,
  t68: `After your personal information exceeds the retention period, we will delete your personal information or anonymize it in accordance with legal requirements.`,
  t69: `5、 Your rights`,
  t70: `According to relevant laws, regulations, and standards in your country, we guarantee that you exercise the following rights over your personal information:`,
  t71: `（1） Access your personal information`,
  t72: `You have the right to access your personal information through the user center on your own. Please refer to (VI) how we respond to your request, except for exceptions specified by laws and regulations`,
  t73: `（2） Correcting your personal information`,
  t74: `When you discover errors in the personal information we process about you, you have the right to request us to make corrections. How to correct personal information can be found in (6) How we respond to your request.`,
  t75: `（3） Delete your personal information. In the following situations, you can request us to delete your personal information:`,
  t76: `1. Our handling of personal information violates laws and regulations;`,
  t77: `2. We collect and use your personal information without your consent;`,
  t78: `3. Our handling of personal information violates our agreement with you;`,
  t79: `4. You no longer use our products or services, or you have cancelled your account;`,
  t80: `5. We will no longer provide you with products or services.`,
  t81: `How to delete personal information can be found in (VI) How we respond to your request. If we decide to comply with your request, we will also notify the entities that have obtained your personal information from us to request timely deletion, unless otherwise provided by laws and regulations or with your independent authorization.`,
  t82: `When you delete information from our service, we may not immediately delete the corresponding information in the backup system, but we will delete this information during backup updates.`,
  t83: `（4） Revoke consent and disable usage function`,
  t84: `You can change the scope of your authorization for us to continue collecting personal information or revoke your authorization by disabling device functionality. You can also revoke all our authorization to continue collecting your personal information by canceling your account. How to withdraw consent and disable the use function can be found in (VI) How do we respond to your request`,
  t85: `（5） Personalized recommendation management`,
  t86: `1. Manage personalized advertising information recommendations`,
  t87: `Personalized advertising is one of the advertising display technologies, where advertisers make intelligent recommendations based on your preferences within the application. If you do not want to be recommended for personalized advertising, you can choose whether to receive personalized advertising recommendations on the [My] - [System Settings] - [Personalized Advertising Recommendations] switch. After closing, the relevance of the advertisement you see will decrease.`,
  t88: `2. Manage personalized content recommendations`,
  t89: `To ensure that you can watch the content you are interested in, we offer the ability to recommend personalized content. If you do not want to be recommended for personalized content, you can turn off personalized content recommendation by using the [My] - [System Settings] - [Personalized Content Recommendation] switch. After closing, the homepage will not display recommended channels. Switching to selected channels will reduce content relevance.`,
  t90: `（6） Account cancellation`,
  t91: 'You can cancel your account in `My` - `System Settings` - `Cancel Account`. You can cancel your previously registered account at any time, please refer to the `Cancellation Notice` for details.',
  t92: `After canceling your account, we will stop providing you with products or services and delete your personal information, except as otherwise provided by laws and regulations.`,
  t93: `In the following situations, according to legal requirements, we will not be able to comply with your personal information deletion request:`,
  t94: `1. Directly related to national security and national defense security;`,
  t95: `2. Directly related to public safety, public health, and major public interests;`,
  t96: `3. Directly related to criminal investigation, prosecution, trial, and enforcement of judgments;`,
  t97: `4. There is sufficient evidence to indicate that you have subjective malice or abuse of power;`,
  t98: `5. Responding to your request will result in serious damage to your or other individuals or organizations' legitimate rights and interests;`,
  t99: `6. Involving trade secrets.`,
  t100: `（7） In response to your above request`,
  t101: 'You can choose to disable the permissions you wish to disable through the `Settings` function on your phone.',
  t102: 'You can also make a phone call by clicking on `My` - `About Us` - `Contact Customer Service` and then contact customer service to perform user operations such as personal information inquiry, personal information correction, personal information deletion, user account cancellation, and revocation of agreed authorization. To ensure security, you may need to provide a written request or other means to prove your identity. We may first request you to verify your identity before processing your request.',
  t103: `We will provide a response within fifteen working days. If you are not satisfied, you can also appeal by contacting customer service.`,
  t104: `6、 How do we handle children's personal information`,
  t105: `Our products, websites, and services are primarily aimed at adults. We attach great importance to the protection of personal information of children and minors. If you are a minor under the age of 18, before using the relevant services on this platform, you should read and agree to this privacy policy under the supervision and guidance of your parents or other guardians, and use our services or provide information to us with the consent of your guardians.`,
  t106: `For cases where we collect personal information of minors using our products or services with the consent of our parents or guardians, we will only use, share, transfer, or disclose this information when permitted by laws and regulations, explicitly agreed upon by our parents or guardians, or necessary to protect minors. If we find that we have collected personal information of minors without obtaining verifiable parental consent in advance, we will delete the relevant information as soon as possible.`,
  t107: `7、 How to transfer your personal information globally`,
  t108: `At present, we do not transmit or store your personal information across borders. If cross-border transmission or storage is required in the future, we will inform you of the purpose, recipient, security measures, and security risks of the information being exported, and obtain your consent.`,
  t109: `8、 How to update this policy`,
  t110: `After the update of this privacy policy, this platform will display the updated privacy policy and content to you in the form of notifications, pop ups, and website announcements when you log in and version updates, so that you can stay informed of the latest version of this privacy policy in a timely manner. We will strictly follow the privacy policy collection and usage rules disclosed to you in conducting personal information processing activities. If the purpose of personal information use changes, we will obtain your consent again. If you continue to use our services, it means that you agree to accept the revised content of this policy.`,
  t111: `9、 Application of this policy`,
  t112: `All services of this product are subject to this policy.`,
  t113: `When you first log in to this product, we will prompt you to read this policy and obtain your consent. Unless otherwise agreed or required by law, this privacy policy does not apply to products or services linked to other third parties by ${window.company} Technology and the registered copyright owner of this product. Your use of these third-party services (including any personal information you provide to these third parties) will be subject to the terms of these third-party services and privacy policies (rather than this privacy policy). Please carefully read the terms of these third parties for specific provisions. Please protect your personal information properly and only provide it to third parties when necessary.`,

  p1: `This Privacy Policy (referred to as this Policy) was issued and effective on March 15, 2023, and was last revised on April 10, 2023. This Policy is a valid contract between ${window.region} ${window.company} Technology Co., Ltd. (hereinafter referred to as '${window.company} Technology', '${window.company}' or 'us') and the user (also referred to as 'you') regarding privacy protection related matters of the client product Hippo Theater (hereinafter referred to as 'this Product'). By clicking to confirm this agreement or choosing to accept it in any other way, you have reached an agreement with ${window.company} Technology and ${window.company} and agree to accept all the following provisions of this policy.`,
  p2: `${window.region} ${window.company} Technology Co., Ltd. is the registered copyright owner of this product and enjoys the copyright of this product in accordance with the law.`,
  p3: `This product is jointly operated by ${window.company} Technology and ${window.company} to provide you with services and enjoy rights, fulfill obligations, and assume legal responsibilities for you in accordance with the law.`,
  p4: `This policy will help you understand the following content:`,
  p5: `1. How do we collect and use your personal information`,
  p6: `2. How do we use cookies and similar technologies`,
  p7: `3. How do we share, transfer, and publicly disclose your personal information`,
  p8: `4. How do we protect your personal information`,
  p9: `5. Your rights`,
  p10: `6. How do we handle children's personal information`,
  p11: `7. How to transfer your personal information globally`,
  p12: `8. How to update this policy`,
  p13: `9. Application of this policy`,
  p14: `10. Dispute resolution`,
  p15: `${window.company} Technology and the registered copyright owner of this product attach great importance to the protection of personal information. When users (referred to as 'you') use our products or services, we may collect and use your relevant information. Once you choose to use our products or services, it means that you acknowledge and accept the existing content of these terms and any updates that may be made at any time. If you do not agree, the service of using this product should be terminated. Please carefully read and understand this Privacy Policy before using our products. `,
  p16: `1、 Please carefully read and understand all rights and limitations stipulated in this agreement. Once you install, copy, download, access or otherwise use the product, it will be deemed as acceptance of this agreement, and you agree to be bound by the terms of this agreement. You must ensure that you download or use this software product from the designated website and obtain it from legally distributed media. Otherwise, we will not be responsible for any potential risks and losses caused by this. We remind you that if you are under 18 years old or do not have full capacity for civil conduct, please read this agreement with your legal guardian and pay special attention to the terms related to the use of this service by minors. Otherwise, we shall not be liable for any adverse consequences that may arise from subsequent registration, use of this service, or payment recharge, except as expressly provided by law. We have the right to terminate the user agreement between both parties upon becoming aware of such circumstances.`,
  p17: `2、 Declaration of Rights`,
  p18: `1. ${window.region} ${window.company} Technology Co., Ltd. is the registered copyright owner of this software and enjoys the copyright of this software in accordance with the law.`,
  p19: `2. Without the written consent of ${window.company} Technology and the registered copyright owner of this software, users are not allowed to independently implement, utilize, transfer or license any third party to implement, utilize, transfer the aforementioned software copyright and other intellectual property for any profit or non-profit purposes. ${window.company} Technology and the registered copyright owner of this software reserve the right to pursue the aforementioned unlicensed behavior.`,
  p20: `3. Prohibition of Reverse Engineering, Reverse Compilation, and Reverse Assembly: Users are not allowed to reverse engineer, decompile, or disassemble this software product, and are not allowed to modify any resources compiled within the program files. Except as expressly permitted by laws and regulations, users must comply with the limitations of this agreement.`,
  p21: `4. Component segmentation: This software product is licensed for use as a whole, and users are not allowed to use each part separately for any purpose.`,
  p22: `5. Individual Authorization: If commercial sales, copying, distribution, including but not limited to software sales, pre installation, bundling, etc. are required, separate written authorization and permission must be obtained from the software copyright owner.`,
  p23: `6. Reserved Rights: All other rights not expressly authorized in this agreement shall remain the property of ${window.company} Technology, and users must obtain written consent from ${window.company} Technology when using other rights.`,
  p24: `7. The video content provided by this software only represents the author's own position and viewpoint, and is not related to ${window.company} Technology and the registered copyright owner of the software. The author shall bear all legal responsibilities.`,
  p25: `3、 User Instructions`,
  p26: `1. Software functions: search and watch short dramas; Recharge, download, and purchase short dramas; Watch and save the viewing progress.`,
  p27: `2. Regarding payment for digital content: All revenue generated from  user payments will be collected by ${window.company} Technology after deducting the fees charged by third-party payment institutions according to regulations. The digital content within this software consists of free content and paid content, which are divided into limited time free content and permanent free content. ${window.company} Technology has the right to decide on the standards and charging methods of the services provided, and provide prompts on the relevant service pages regarding the pricing standards. Users have the right to choose whether to pay the corresponding fees to continue using the paid services provided by this software. If you are a minor under the age of 18 (especially a minor under the age of 14), you should recharge with the consent of your legal guardian. After recharging on this software, users will receive corresponding viewing points, which can be used to purchase the paid services provided by this software. Your agreement to this agreement shall be deemed as your agreement to grant ${window.company} Technology the authorization to automatically purchase the content of the next episode and bulk purchase the content of the series for your paid service. Once a user successfully recharges (including minors who have successfully recharged with the consent of their guardians), they shall not demand a refund of all or part of the recharged amount for illegal reasons, except in cases of force majeure, agreement agreements, or relevant laws and regulations.`,
  p28: `3. Software modification and upgrade: The copyright owner of this software reserves the right to provide users with modified and upgraded versions of this software at any time. After the user selects and confirms, the software will be upgraded and updated, and corresponding data traffic fees will be generated by using the operator's network, which will be charged by the operator.`,
  p29: `4. Users should use this software in compliance with laws and this agreement. Users are not authorized to engage in the following behaviors, including but not limited to:`,
  p30: `(1) Delete or change all rights management electronic information on this software;`,
  p31: `(2) Intentionally avoiding or damaging the technical protection measures taken by the copyright owner to protect the copyright of this software;`,
  p32: `(3) Using this software to mislead and deceive others;`,
  p33: `(4) Violating national regulations by deleting, modifying, adding, or interfering with the functions of computer information systems, resulting in the malfunction of computer information systems;`,
  p34: `(5) Unauthorized access to computer information networks or use of computer information network resources;`,
  p35: `(6) Unauthorized deletion, modification, or addition of computer information network functions;`,
  p36: `(7) Unauthorized deletion, infiltration, modification, or addition of data and application programs stored, processed, or transmitted in computer information networks;`,
  p37: `(8) Disrupting the normal operation of this software system or website, intentionally spreading destructive programs such as computer viruses;`,
  p38: `(9) Any other behavior that endangers computer information network security;`,
  p39: `(10) Other abnormal usage behaviors.`,
  p40: `5. For this software product downloaded from non designated sites or obtained from illegally distributed media, ${window.company} Technology cannot guarantee whether the software is infected with computer viruses, whether there are disguised Trojan programs or hacker software hidden. The use of such software by users may lead to unpredictable risks. It is recommended that users do not download, install, or use it easily. ${window.company} Technology and the registered copyright owner of this software do not assume any legal responsibility arising from this.`,
  p41: `6. Privacy protection`,
  p42: `${window.company} Technology promises to comply with the Privacy Policy (also known as the 'Privacy Agreement') and applicable laws, and has formulated the following four privacy protection principles to guide us in dealing with issues related to user privacy and information in our products:`,
  p43: `(1) Before collecting your information, we will clearly inform you of the method, scope, and purpose of information collection and obtain your consent. We use the information we collect to provide users with valuable products and services only. We will never exceed the scope or frequency of collecting your personal information, such as contacts, location, ID card, facial information, etc., and will never collect information that is not necessary or has no reasonable application scenarios for this software service;`,
  p44: `(2) Develop products that comply with privacy standards and privacy practices. Without the user's consent, personal information such as device identification information, product browsing history, search usage habits, and commonly used software application lists shall not be shared or used with other application software. Never use collected personal information such as user searches, browsing history, usage habits, etc. for targeted promotion or precision marketing without informing or prominently indicating to users, and do not provide users with the option to disable this function;`,
  p45: `(3) Transparent collection of personal information;`,
  p46: `(4) Make every effort to protect the user information we have access to.`,
  p47: `Based on this, you agree to authorize ${window.company} Technology to use your user profile picture and nickname, so that we can better serve you.`,
  p48: `7. After becoming a VIP member, during the membership period, you can watch all short drama content on this platform.`,
  p49: `8. During the installation and operation of this software, ${window.company} Technology will never request any permissions unrelated to the user's current usage scenario. Moreover, after the user explicitly refuses the permission application, they should never frequently apply to enable permissions unrelated to the current service scenario, such as contact list, location, SMS, recording, camera, etc., in order to force the user to agree to the activation of permissions unrelated to this software. When users do not use relevant functions or services, they shall not apply for permission to enable user contacts, location, SMS, recording, camera, etc. in advance, or apply for permission to enable contacts, location, SMS, recording, camera, etc. beyond the business functions or services of this software product. The clipboard permission is only used in scenarios where the user chooses to copy the customer service phone number. If the user does not choose to copy, ${window.company} Technology will never apply to the user to enable clipboard permission.`,
  p50: `9. Users have the right to cancel their personal account information. After the user cancels their personal account information, ${window.company} Technology will delete the personal account information stored by the user in this software (see relevant cancellation notices for details).`,
  p51: `10. When you reinstall the mobile system or clean up mobile application software, all your user information will be lost, including but not limited to: personal account information, records of searching or browsing dramas, downloaded or purchased dramas, viewing records, viewing progress, membership benefits, and at the same time, all views in your personal account will be reset to zero. You are aware that this is determined by the characteristics of the product, but you can avoid losing your relevant user information when reinstalling the mobile system or cleaning up mobile applications by associating your personal account with a phone number. When you fail to associate your personal account with a mobile phone number, in the event of user information loss, ${window.company} Technology and the copyright owner of this software will not be held responsible for this.`,
  p52: `11. You are not allowed to engage in illegal activities or engage in abnormal consumption in any way on this software platform or by exploiting system vulnerabilities, program defects, human operation errors, etc. You are not allowed to use malicious software or any other means to disrupt the normal operation of this software platform. You are not allowed to engage in any activities that violate the principle of good faith. Otherwise, once you are recognized by this software platform as having the aforementioned behavior, ${window.company} Technology has the right to directly freeze or cancel your account without prior notice and will not return the account balance. All losses arising from this will be borne by you. In addition, ${window.company} Technology still reserves the right to pursue your legal responsibility in accordance with the law. If your actions cause losses to ${window.company} Technology, you should compensate for all the losses caused to ${window.company} Technology. If you are suspected of committing a crime, ${window.company} Technology has the right to transfer the relevant clues to the judicial department for processing.`,
  p53: `We attach great importance to the protection of personal information and legitimate rights and interests of minors, and actively promote their healthy growth. Minors (especially those under the age of fourteen) who use the services under this agreement without the accompaniment and consent of their parents (guardians) shall be promptly notified by their parents (guardians) upon discovery. We will assist their parents (guardians) in handling their registered accounts and accounts to ensure that their guardianship responsibilities and obligations are fulfilled.`,
  p54: `4、 Protection of intellectual property rights for users and third parties`,
  p55: `1. During the use of the services included in this software, users may need to provide content to ${window.company} Technology through various means such as posting comments. All original content published by users on this software is owned by the user themselves. The comments posted by users only represent their opinions and are not related to the registered copyright owner of this software or ${window.company} Technology.`,
  p56: `2. Users are not allowed to infringe on intellectual property rights, including the copyrights of others, as well as other rights. In case of any legal disputes arising from the content published by the user, the responsibility shall be borne by the user themselves.`,
  p57: `3. For any content published by the user on this software, the user agrees to authorize ${window.company} Technology to have a free, permanent, and non exclusive right to use all copyrights worldwide. The user licenses ${window.company} Technology to take separate legal action against any infringement by any party (including but not limited to litigation, reporting, issuing lawyer letters, etc.).`,
  p58: `4. If the user's comments infringe on the copyright or other rights of a third party or violate legal provisions, after the third party raises objections or is discovered through the content review process, ${window.company} Technology has the right to delete the relevant content and has the right to pursue or assist the third party in pursuing the user's legal responsibility.`,
  p59: `5. Without written permission from ${window.company} Technology, any entity is prohibited from using any crawler program, spider program, anthropomorphic program, infiltration technology, hacker program, and other automatic devices, or manual programs to invade, read, copy, store this software or any content contained therein, or engage in any illegal or dishonest activities. Otherwise, ${window.company} Technology has the right to directly freeze or cancel your account without notifying you, and has the right not to return the account balance. All losses incurred as a result will be borne unilaterally by you. In addition, ${window.company} Technology still has the right to hold you legally responsible to compensate for all losses caused to ${window.company} Technology due to your above-mentioned actions, in order to effectively protect the legitimate rights of ${window.company} Technology.`,
  p60: `5、 Content Copyright Statement`,
  p61: `${window.company} Technology promises to abide by and respect the protection of copyright owners under current Chinese laws, regulations, and other normative documents. As the rights holder, when you discover that the provided content infringes on your legitimate rights and interests, you should first send us a 'rights notice' according to the processing process cs{'@'}ishugui.com Or call customer service at 400-118-0066. We will take measures to remove relevant content or disconnect or block related links in accordance with Chinese laws and regulations and government normative documents. After taking the aforementioned measures, you shall not hold us and the registered copyright owner of this software liable for any legal liabilities.`,
  p62: `6、 Disclaimer and Limitation of Liability`,
  p63: `1. The user confirms that they are aware of all the functions of this software and the necessary operations to implement each function of this software. They voluntarily choose to use this software and related services according to their own needs. The risks and all consequences of using this software and related services will be fully borne by you in accordance with the law. The registered copyright owner of this software and ${window.company} Technology shall not be held responsible.`,
  p64: `2. This software has undergone detailed testing, but we cannot guarantee full compatibility with all software and hardware systems, nor can we guarantee that this software is completely error free. If there are incompatibilities and software errors, users can call customer service hotline 400-118-0066 to report the situation and obtain technical support. If compatibility issues cannot be resolved, users can delete this software.`,
  p65: `3. To the maximum extent permitted by applicable law, the registered copyright owner of this software and ${window.company} Technology shall not be liable for any damages and risks arising from your use or inability to use this software, including but not limited to direct or indirect personal damage, loss of commercial profits, trade interruption, loss of commercial information, or any other economic losses.`,
  p66: `4. The registered copyright owner of this software and ${window.company} Technology will not bear any responsibility for any loss caused by information damage or loss, mobile phone system problems or any other force majeure reasons due to telecommunications system or Internet network failure, mobile phone failure or virus program operation.`,
  p67: `5. If the user violates the provisions of this agreement and causes damage to the registered copyright owner of this software and ${window.company} Technology, ${window.company} Technology has the right to take measures including but not limited to interrupting the user's usage license, stopping the provision of services, restricting use, and pursuing legal responsibilities.`,
  p68: `7、 Legal and Dispute Resolution`,
  p69: `1. This agreement is governed by the laws of your country.`,
  p70: `2. Any disputes arising from or related to this agreement shall be resolved through friendly consultation between the parties; If the negotiation fails, either party has the right to submit the dispute to the arbitration commission for arbitration.`,
  p71: `8、 Other terms`,
  p72: `1. If any provision of this Agreement is completely or partially invalid or unenforceable for any reason, or violates any applicable law, such provision shall be deemed deleted, but the remaining provisions of this Agreement shall remain valid and binding.`,
  p73: `2. ${window.company} Technology has the right to modify this agreement at any time in accordance with changes in relevant laws and regulations, as well as adjustments to the company's operating conditions and strategies. The revised agreement will be published on the website and attached to the new version of the software. When disputes arise, the latest agreement text shall prevail. If the user disagrees with the changes, they can delete the software themselves. If the user continues to use this software, it shall be deemed that you have accepted the changes to this agreement.`,
  p74: `3. ${window.company} Technology has the right to interpret and modify this agreement to the maximum extent permitted by law.`,

  login_first: `Please login`,
  sessionTimeout: `Please login again`,
  unknown_err: `Unknown error`,
  way: `Method`,

  starCardPlan: `M-Card`,
  starCardPrivate: `M-Card Benefits`,
  starCardIntroduction: `M-Card is the proof of rights for the M-Plan promotion system. M-Card is divided into M-Card and M-Card Pro. Becoming M-Card user can earn rewards.`,

  banQuanZhongChou: `Copyright`,
  banQuan1: `Copyright yield of 130% and more rewards.`,
  banQuan2: `Copyright yield of 150% and more rewards.`,

  enterPhoneNumber: `Please enter your phone number`,
  enterRightNumber: `Please enter the correct phone number`,
  pwdNotNull: `The verification code cannot be empty`,
  checkAgreement: `Please agree to the privacy policy and user agreement first`,
  second: `Sec`,
  sendSuccess: `Send Successful`,
  codeValid: `Get Code`,

  promoteCount: `Number of M-Team`,
  today: `Today`,
  promoteList: `Promotion List`,
  nickName: `Nickname`,
  level: `Level`,
  register: `Register`,
  noMore: `No more content`,
  page: `Page`,
  drawout: `Withdraw`,
  logining: `Logging`,
  shortMoive: `Skits`,
  otherLogin: `Login With Other Methods`,

  uploading: `Uploading`,
  timeout: `Please check the network connection`,
  uploadLimitOne: `You can only upload one image`,
  unit: `${window.currency}`,

  drawoutNotice: `<p>1.Please fill in the real account information. User shall bear the loss caused by incorrect account information.</p>
  <p>2.Withdrawal require review. Withdrawal may be delayed for a while.</p>
  <p>3.Minimum withdrawal amount ${window.currency}$extractSingleMin.</p>
  <p>4.Maximum withdrawal amount ${window.currency}$extractSingleMax.</p>
  <p>5.Withdrawal fee $extractChargeRate, will deduct from the withdrawal amount.</p>
  <p>6.You can apply for $extractDailyCount withdrawals per day.</p>
  <p>7.Withdrawal will be received within one day.</p>`,

  submitItem: `Submit`,
  caresure: `Tip`,
  server_bank: `Bank`,
  server_bankCardNo: `Account Number`,
  server_bankCardHolder: `Account Name`,
  server_email: `Email`,
  server_phone: `Phone`,

  pleaseInputPwd: `Please enter password`,
  useCodeLogin: `Login With Verification Code`,
  usePwdLogin: `Login With Password`,
  pwdLimitTip: `Password should be 6-20 characters`,
  pleaEnterInviteCode: `Please enter the invitation code`,
  setPwd: `Set Login Password`,
  pwdPlaceholder: `Please confirm password length of 6-20 characters`,
  optSuccess: `Successful`,

  Balance: `Balance`,
  WithdrawAmountTip: `Withdrawal amount cannot be empty`,
  WithdrawAmountTipMin: `Your wallet balance is less than the minimum withdrawal amount`,
  WithdrawAmountTipDecimal: `The withdrawal amount is accurate two decimal places`,
  TransferAmountTip: `Transfer amount cannot be empty`,
  TransferAmountTipMin: `Your wallet balance is less than the minimum transfer amount`,
  TransferAmountTipDecimal: `The Transfer amount is accurate two decimal places`,
  Submit: `Submit`,

  BankOfDeposit: `Deposit of Bank`,
  BankOfDepositTip: `Bank name can not be empty`,
  BankCardNumber: `Bank Card Number`,
  BankCardNumberTip: `Bank card number can not be empty`,
  userName: `Username`,
  userNameTip: `Username can not be empty`,

  TransferUserID: `User ID`,
  TransferUserIDTip: `User ID can not be empty`,
  ReTransferUserID: `Confirm user ID`,
  ReTransferUserIDTip: `User ID cannot be empty`,
  TransferUserIDTips: `The user ID entered twice is inconsistent`,

  VerificationCode: `Verification Code`,
  VerificationCodeTip: `The verification code can not be empty`,
  GetVerificationCode: `Get Code`,

  Precautions: `Tip`,
  PrecautionsTip1: `1.Please fill in the real account information. User shall bear the loss caused by incorrect account information.`,
  PrecautionsTip2: `2.Withdrawal require review. Withdrawal may be delayed for a while.`,
  PrecautionsTip3: `3.Minimum withdrawal amount 1.`,
  PrecautionsTip4: `4.Maximum withdrawal amount 1.`,
  PrecautionsTip5: `5.Withdrawal fee 1, will deduct from the withdrawal amount.`,
  PrecautionsTip6: `6.You can apply for 1 withdrawal per day.`,
  PrecautionsTip7: `7.Withdrawal will be received within one day.`,

  confirmPay: `Confirm Payment $y`,
  goPay: `Pay $y now`,
  couponState: `Type Mismatch`,
  couponNo: `Redemption code not available`,
  coupinErr: `Redemption code is incorrect`,
  couponUse: `Discount $y`,
  BalanceDeductions: `Wallet balance pays $y`,
  ExchangeStarCard: `Redeem M-Card`,
  EnterMembershipTips: `Redeem M-Card or M-Card Pro for a few days`,
  EnterMembership: `Redeem $x  for  $y days`,
  ExchangeNotUsed: `The  redemption code has not been used`,
  ExchangeUsed: `The  redemption code has been used`,
  noExchange: `The  redemption code does not exist`,
  noRedemptionTips: `Please enter the redemption code`,
  PleaseAmount: `Please select the participation amount:`,
  PaymentAgreement: `Payment is considered as your agreement`,
  DramaAgreement: `Drama Rich Star Card Service Agreement`,

  setPayment: `Set Collection Method `,
  saveInfo: `Save Information `,
  myBuy: `Buy`,
  mySell: `Sell`,
  placeOrder: `Place Order`,
  whole: `All`,
  myOrder: `My Orders`,
  anonymousUser: `Anonymous User`,
  deal: `Deal`,
  sell: `Sell`,
  integral: `Points`,
  demand: `Demand`,
  minute: `Minute`,
  sellOut: `Sell Out`,
  purchase: `Buy`,
  pendingOrders: `Pending Orders`,
  confirmOrder: `Confirm Order`,
  choosePaymentMethod: `Select Payment Method`,
  back: `Back`,
  orderGenerated: `Order Generated`,
  please: `Please`,
  paymentInformation: `Confirm Buyer Payment Information`,
  reportExceptions: `Report Exception`,
  sellerToConfirm: `Waiting for Seller to Confirm Payment`,
  ordersCannotStage: `Order Cannot Be Cancelled at This Stage`,
  orderCompleted: `Order Completed`,
  contactustomer: `If there are objections, please contact customer service for mediation`,
  customerServiceMediation: `Customer Service Mediation`,
  confirmCompletion: `Confirm Completion`,
  PleaseWaitPayment: `Please Wait for Buyer Payment`,
  reportCorrect: `Report Order Exception, Is This Correct?`,
  addMethod: `Add Payment Method`,
  selectType: `Select Type`,
  sellPoints: `Sell Points`,
  sellUsers: `Sell Points from Account to Other Users`,
  purchasePoints: `Purchase Points`,
  canPointsUsers: `Can Purchase Points from Other Users`,
  messageBoard: `Message Board`,
  you: `You`,
  reply: `Reply`,
  enterBelow: `Please Enter Reply Information Below`,
  viewDetails: `View Details`,
  paymentMethod: `Payment Method`,
  addPaymentInformation: `Add Payment Information`,
  paymentDeadline: `Payment Deadline`,
  totalAmount: `Total Amount`,
  price: `Unit Price`,
  tradingPrecautions: `Trading Precautions`,
  afterQuantity: `1. After confirming the order, your points will be locked according to the order quantity.`,
  confirmCancelled: `2. Please confirm the completion of the transaction within the specified time, otherwise, it will be automatically cancelled.`,
  cancellationOrder: `Cancel Order`,
  CancelOrder: `Cancel Listing`,
  transactionDetails: `Transaction Details`,
  myPendingOrder: `My Pending Order`,
  orderType: `Order Type`,
  myTransaction: `My Transaction`,
  orderStatus: `Order Status`,
  buyPoints: `Buy Points`,
  release: `Release`,
  locking: `Locking`,
  end: `End`,
  removeFromShelves: `Remove from Shelves`,
  establish: `Create`,
  approachingTimeout: `Approaching Timeout`,
  overtime: `Timeout`,
  buyerConfirmed: `Buyer Confirmed`,
  sellerConfirmed: `Seller Confirmed`,
  successfulTrade: `Trade Successful`,
  createOrder: `Create Buy Order`,
  createSellOrder: `Create Sell Order`,
  enterPurchaseQuantity: `Enter Purchase Quantity`,
  enterSale: `Enter Sale Quantity`,
  pointsBalance: `Points Balance`,
  setMaxQuantity: `Set Maximum Quantity`,
  setPurchasePrice: `Set Purchase Price (Total Price)`,
  setSalePrice: `Set Sale Price (Total Price)`,
  rangePrice: `Points Unit Price Range`,
  confirmPeddingOrder: `Confirm Listing`,
  pointsRange: `Points Unit Price Out of Range`,
  orderSuccessfully: `Order Successfully Placed`,
  OrderErr: `Order Error`,
  OrderOverTime: `Order Timeout`,
  CustomeRservice: `If you have any objections, please contact customer service for mediation`,
  UpdataPedding: `Modify Pending Order`,
  CustomerService: `Customer Service Mediation`,
  WaitingPayment: `Waiting for Payment`,
  Collectionpayment: `Waiting for Collection`,
  ConfirmCompletion: `Confirm Completion`,
  ContactCustomer: `Contact Customer Service for Mediation`,
  NumberPending: `Number of Pending Orders Today`,
  Edit: `Edit`,
  Create: `Create`,
  Bill: `Bill`,
  SellBill: `Sell Bill`,
  PendingOrders: `Pending Orders`,
  Reason: `Reason`,
  Kong: `NoData`,
  USDTAmout: `After withdrawing to USDT, the amount is $slot`,

  //new
  WALLET: 'WALLET',
  THIRD_PAY: 'THIRD_PAY',
  enterPassword: `Please enter the password`,
  enterSecondPassword: `Please enter the second password`,
  confirmPassword: `The two passwords are inconsistent`,
  registerIn: `Register and log in now`,
  LoginCode: `Login With Verification Code`,
  LoginPassword: `Login by password`,
  goLogin: `go login`,
  goRegister: `go register`,
  ClearAccount: `Are you sure you want to clear your account?`,

  Using: `Using`,
  SwitchAccount: `Switch account`,
  AddAcount: `Add account`,
  Finish: `Finish`,

  // newHome
  AppDesc: `Innovative Short Video Sharing Platform`,
  AppIntroduce: `MovShot is a cutting-edge platform that combines entertainment and earning opportunities for users who enjoy watching short dramas. The platform enables users to earn money by engaging with short dramas through various interactive features such as watching, commenting, and sharing.`,
  DownloadNow: `Download Now`,
  SignIn: `Sign in`,
  MarketBackground: `MARKET BACKGROUND`,
  MarketDesc: `With over 1 billion short video users globally, 50.4% have watched micro-dramas under 3 minutes in length. Industry forecasts suggest that by 2023, the market size of short dramas could reach between $2.8 billion and $4.2 billion, with continuous growth expected into 2024.`,
  ProductFeatures: `PRODUCT FEATURES`,
  AppFeature1: `1.Diverse Content: `,
  AppFeatureDesc1: `MovShot offers a rich library of short dramas, covering genres like comedy, romance, suspense, and more, catering to a wide range of user preferences.`,
  AppFeature2: `2.Earning Model: `,
  AppFeatureDesc2: `MovShot allows users to earn real money by watching, commenting on, and sharing short dramas. This unique monetization model makes it highly attractive to users.`,
  AppFeature3: `3.Interactive Community: `,
  AppFeatureDesc3: `MovShot fosters a strong sense of community by allowing users to like, comment, and share content, encouraging active interaction between users.`,
  AppFeature4: `4.Personalized Recommendations: `,
  AppFeatureDesc4: `Using intelligent algorithms, MovShot provides personalized content recommendations based on user interests, increasing engagement and satisfaction.`,
  CompetitiveAdvantages: `COMPETITIVE ADVANTAGES`,
  CompetitiveAdvantages1: `1.Open Licensing Promotion and Distribution: `,
  CompetitiveAdvantages1_1: `MovShot taps into open licenses, enabling untapped content to be promoted on the platform and realize its full commercial potential.`,
  CompetitiveAdvantages2: `2.Self-Produced High-Quality Short Dramas: `,
  CompetitiveAdvantages2_1: `MovShot invests in creating original high-quality short dramas, enriching its content library and enhancing user retention.`,
  CompetitiveAdvantages3: `3.Micro-Drama Actor IP Management: `,
  CompetitiveAdvantages3_1: `By developing IP for micro-drama actors, MovShot leverages the influence of actors to enhance the platform’s visibility, driving user engagement and traffic conversion.`,
  CardTitle1: `INDUSTRY CHAIN LAYOUT`,
  CardDesc1: `MovShot integrates the content production and distribution process, with copyright owners, distributors, and streaming agents working together to ensure smooth content flow from upstream to downstream.`,
  CardTitle2: `M CARD SYSTEM`,
  CardDesc2: `The M Card is a credential for users to participate in MovShot Pro's earnings program. Available in two types, Trial M Card and Premium M Card, both provide access to cash rewards and other benefits.`,
  CardTitle3: `COPYRIGHT INVESTMENT`,
  CardDesc3: `MovShot offers a unique investment opportunity where users can invest in the promotion of copyrighted dramas, earning a share of the resulting profits. The returns fluctuate based on the promotion cycle of each drama.`,
  CardTitle4: `Social and Team-Based Earning`,
  CardDesc4: `MovShot introduces a social and team-based earning system, allowing users to form teams and use their networks to maximize earnings. Team-based promotions unlock additional rewards and bonuses, boosting the platform's appeal.`,
  UserFeedback: `USER FEEDBACK`,
  UserFeadbackDesc: `MovShot has received positive feedback from its users!`,
  Career1: `Student`,
  Career2: `Teacher`,
  Career3: `Programmer`,
  Career4: `Driver`,
  UserTalk1: `"I can earn money while being entertained—it's amazing!"`,
  UserTalk2: `· "The short dramas are rich and engaging, and the earning model is very attractive."`,
  UserTalk3: `"MovShot's personalized recommendations always help me find dramas I love."`,
  UserTalk4: `·"The interactive community helps me make new friends."`,
  Platfrom: `Platform Vision`,
  PlatformDesc: `MovShot aims to continue enhancing its content, user experience, and user base while exploring new monetization strategies for sustainable growth. By staying attuned to market trends and user needs, MovShot is poised for continuous success in the evolving short drama market`,
  Movshot: `ReelDao`,
  Contact: `Contact`,

  ContactDesc: `We're a friendly team that would love to hear your ideas, feedback and questions.`,
  Message: `Message...`,
  EmailErr: `Email format error`,
  FormErr: `Please fill out the complete form`,
  FormDesc: `Enter at least 10 characters`,
  noPaymentChannel: `No payment channels`,
  pleaSelectCoinType: `Please select the currency type`,
  overDelete: `Cancelled`,
  InputInvite: `Please enter the invitation code (optional)`,
  registerSuccess: `register Success`,
  WebVersion: `Web Version`,

  //new Copywriting
  AppDescPro: `Web3 AI + Short Drama Matrix`,
  AppIntroducePro: `MovShot is an innovative short video sharing platform where users can watch captivating short dramas and earn rewards through engagement. By integrating Web3 technology, the platform offers an immersive, decentralized creation and distribution experience, driving forward the evolution of the short video industry.`,
  MarketBackgroundPro: `About Movshot`,
  MarketDescPro: `MovShot combines blockchain and AI technology to provide a transparent, free environment for creation and interaction.`,
  ProductFeaturesPro: `Product Highlights`,
  AppFeaturePro1: `1.Earning Model: `,
  AppFeatureDescPro1: `Users can earn real rewards by watching, commenting on, and sharing short dramas.`,
  AppFeaturePro2: `2.Interactive Community: `,
  AppFeatureDescPro2: `Features like likes, comments, and shares foster an active community atmosphere.`,
  AppFeaturePro3: `3.IPersonalized Recommendations: `,
  AppFeatureDescPro3: `Intelligent algorithms recommend dramas based on user interests, enhancing the viewing experience.`,
  AppFeaturePro4: `4.IDiverse Content: `,
  AppFeatureDescPro4: `A wide variety of short dramas, including comedy, romance, suspense, and more, caters to diverse viewing preferences.`,
  CardTitlePro1: `M Card Program`,
  CardDescPro1: `The M Card is a key credential in the MovShot Pro promotion system, offering users various earning and reward opportunities. Users can choose between a trial or premium M Card, gaining cash rewards and additional privileges.`,
  CardTitlePro2: `Social and Team Earnings`,
  CardDescPro2: `MovShot also introduces a social network and team-based earning model. Users can form teams and maximize earnings through sharing and promotion, effectively enhancing user engagement and the platform's profitability potential.`,
  CardTitlePro3: `Global Short Drama Ecosystem`,
  CardDescPro3: `MovShot brings together creators and audiences worldwide to collaboratively shape the future of short dramas.`,
  PlatfromPro: `Join Us`,
  PlatformDescPro: `MovShot warmly invites creators, viewers, and investors to join us in reshaping the future of short dramas.`,
  EntryTitle1: `Innovative Web3`,
  EntryTitle2: `Short Drama Experience`,
  EntryContent: `Leveraging the decentralized and value-transferring benefits of Web3, MovShot is transforming the short video industry`,
  CompetitiveAdvantagesPro1: `1.Decentralized Creation and Fan Economy: `,
  CompetitiveAdvantagesPro1_1: `Supports decentralized creation, offering users new opportunities for participation and investment.`,
  CompetitiveAdvantagesPro2: `2.Ecosystem Incentives and Cross-Sector Partnerships: `,
  CompetitiveAdvantagesPro2_1: `Through mechanisms like airdrops, copyright protection, and asset integration, MovShot creates more opportunities for user earnings.`,

  //reelDao
  RAppDescPro: "Web3 AI + Short Drama Matrix",
  
  RAppIntroducePro: "Welcome to the new era of ReelDao, where the creation and experience of short dramas are redefined.",
  
  RMarketBackgroundPro: "About ReelDao",
  
  RProductFeaturesProBg: "ECOSYSTEM",
  
  RProductFeaturesPro: "Ecosystem",
  
  RAppFeaturePro1: "1. Creators",
  
  RAppFeatureDescPro1: "With the Quantum AI Deep Dream Programmer™, creators can transform simple ideas into multidimensional narrative experiences. Smart contract technology ensures copyright for every work, granting unique rights to creators.",
  
  RAppFeaturePro2: "2. Audience",
  
  RAppFeatureDescPro2: "Audiences can enter the narrative universe through the blockchain, not only watching short dramas but also shaping the story's direction through interaction and choices, unlocking multiple endings.",
  
  RAppFeaturePro3: "3. Investors",
  
  RAppFeatureDescPro3: "Investors can directly invest in short dramas through ReelDao's token system, with blockchain technology providing a transparent and secure investment path.",
  
  REntryTitle: "Technology-Driven Content Creation",
  
  RCompetitiveAdvantagesPro1: "1. Deep Dream Programmer™",
  
  RCompetitiveAdvantagesPro1_1: "Helps creators automatically generate scripts, character settings, and scene layouts, materializing vague concepts into multidimensional narratives.",
  
  RCompetitiveAdvantagesPro2: "2. Virtual Interpretation System™",
  
  RCompetitiveAdvantagesPro2_1: "Holographic AI actors evolve based on audience feedback, providing an immersive virtual experience.",
  
  RCompetitiveAdvantagesPro3: "3. Holographic Reality (XR) Matrix",
  
  RCompetitiveAdvantagesPro3_1: "Audiences enter an immersive experience through XR technology, becoming virtual characters in the short drama, breaking the boundaries between virtual and reality.",
  
  RPlatfromPro: "Join Us",
  
  RPlatfromTitle: "🌟 Redefining the Future 🌟",
  
  RPlatformDescPro: "ReelDao welcomes every creator, audience member, and investor to step into a new era of short dramas, unlocking multidimensional creation and experience.",
  
  RContentNow: "Contact Us",
  
  RMore: "Want to know more?",
  
  RContactUs: "Feel free to contact us to co-create the future of short dramas!",
  
  RMarket: "🌟 Hyperdimensional Narrative, New Experience 🌟",
  
  RMarketIntroduce: "ReelDao creates a decentralized, ever-evolving narrative universe for global users through Web3 and holographic AI engines. Here, you are no longer just a bystander, but a shaper of the story, weaving the boundaries between reality and virtuality.",
  
  RMarketIntroduce1: "Decentralized Smart Protocol (DCP)",
  
  RTCCertificate: "We combine blockchain and AI core technology to ensure content transparency and creative freedom, establishing a new interactive model for creators and audiences.",
  
  Rcard1: "Creation Tools",
  
  RcardTitle1_1: "🌟 Intelligent Creation Matrix™ 🌟",
  
  RcardDesc1_1: "Through the Deep Dimension Script Optimizer™ and Emotional Rendering Engine™, AI provides script optimization suggestions for creators, enhancing the emotional immersion of the audience.",
  
  RcardTitle1_2: "🌟 Intelligent Sound and Light Wave Generation 🌟",
  
  RcardDesc1_2: "AI automatically generates sound effects and music based on the plot, enhancing the audience's emotional resonance.",
  
  Rcard2: "Super Circulation Token Economic System™",
  
  RcardDesc2_1: "The ReelDao platform adopts a unique dual-token system, creating a multi-layered super circulation economy.",
  
  RcardDesc2_2p: "Rewards and Voting:",
  
  RcardDesc2_2c: "Users can reward creators with tokens to support their work and participate in governance voting on the platform's future direction.",
  
  RcardDesc2_3p: "Content Access and Interaction:",
  
  RcardDesc2_3c: "Users use tokens to unlock specific content, plot branches, or gain VIP privileges, enhancing the interactive experience.",
  
  RcardDesc2_4p: "Task System Incentives and Staking:",
  
  RcardDesc2_4c: "By completing platform tasks, users can earn tokens and unlock more rights, supporting the platform's long-term stability and continuous circulation.",
  
  Rcard3: "Immersive Interaction and Co-Creation",
  
  RcardTitle3_1: "🌟 Holographic Immersive Interaction 🌟",
  
  RcardDesc3_1: "Through quantum-level real-time interaction, audiences become the core of the narrative, influencing the direction of the narrative universe.",
  
  RcardTitle3_2: "🌟 Decentralized Co-Creation Governance 🌟",
  
  RcardDesc3_2: "Token voting determines content creation and promotion strategies, ensuring multidimensional considerations at every stage.",
  
  Rcard4: "Global Short Drama Ecosystem",
  
  RcardTitle4_1: "🌟 Crossing Cultural Boundaries 🌟",
  
  RcardDesc4_1: "ReelDao will attract global creators and users through the Quantum Gravity Network™, jointly creating the future short drama ecosystem and exploring new forms of entertainment."
};
